/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Version: 4.1.0
Website: https://Themesbrand.com/
Contact: Themesbrand@gmail.com
File: Custom Bootstrap Css File
*/


//Core files
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "../../components/utilities";
@import "bootstrap/scss/bootstrap";

