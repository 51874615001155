/* You can add global styles to this file, and also import other style files */
// Swiper

label.required::after {
  content: " *";
  color: red;
}

.m-0 {
  margin: 0 !important;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-0 {
    padding-top: 0 !important;
}

i.ri-more-2-fill {
  font-size: 22px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.left-text {
  text-align: left!important;
}

.blue-text {
  color: #3577f1!important;
  font-weight: 400!important;
}

.column-fw-small {
  font-weight: 400!important;
  font-size: 90%!important;
}

.column-fw-medium {
  font-weight: 400!important;
}

.d-flex {
  display: flex!important;
}

.right {
  float: right!important;
}

button.btn-badge span {
  position: absolute!important;
  top: 0!important;
  right: 0!important;
  z-index: 2!important;
  padding: 12px!important;
}

.edit-item-btn {
  cursor: pointer!important;
}

.ml-10 {
    margin-left: 10px!important;
}

.coverflowslide .slide {
  position: relative;
  transition: transform 0.3s;
}

.coverflowslide .slick-list {
  overflow: hidden !important;
}

.coverflowslide .slick-center {
  transform: scale(1.2); // Adjust the scale factor for the "Coverflow" effect
  z-index: 1;
}

.coverflowslide .carousel {
  width: 100%;
  max-width: 600px; // Adjust the carousel width as needed
  margin: 0 auto;
}

.space .slick-slide {
  margin: 10px;
}

#notificationItemsTabContent {
  .avatar-title {
    height: 30px !important;
    width: 30px !important;
  }
}

.has-validation ng-select.is-invalid .ng-select-container {
  border-color: var(--vz-form-invalid-border-color);
}

table.right-table-details tbody tr {
  td {
    padding-bottom: 0;
  }

  &:last-child {
    td {
      padding-bottom: 16px;
    }
  }
}

.full-width {
  width: 100% !important;
}

.font-size-12 {
  font-size: 12px!important;
}

.font-size-14 {
  font-size: 14px!important;
}

.font-size-16 {
  font-size: 16px!important;
}

[data-bs-theme="dark"] {
  .fc {
    .fc-multimonth-daygrid {
      background: #212529 !important;

    }

    .fc-day-disabled {
      background: #282b2e !important;
    }

    .fc-multimonth {
      border-color: #32383e !important;
    }
  }
}


#landingnft {
  .slick-dots {
    bottom: -36px;
    z-index: 1;

    .slick-active {
      button {
        background-color: #3577f1 !important;
      }
    }
  }

  .slick-dots li {
    height: 7px !important;
  }

  .slick-dots li button {
    width: 22px !important;
    height: 5px !important;
    border: none;
    background-color: rgba(109, 98, 253, .2) !important;
    padding: 0px;
    margin: 0px;
    transition: all .3s;
    border-radius: 50px;

    &::before {
      display: none;
    }
  }

}

$status-draft: #878a99;
$status-archived: #8d6e63;
$status-cancelled: #f06548;
$status-duplicated: #FFA500FF;
$status-to-process: #4b38b3;
$status-processing: #ffc64e;
$status-by-correction: #3577f1;
$status-processed: #45CB85;

.status-draft {
  color: $status-draft !important;
}

.status-archived {
  color: $status-archived !important;
}

.status-cancelled {
  color: $status-cancelled !important;
}

.status-duplicated {
  color: $status-duplicated !important;
}

.status-to-process {
  color: $status-to-process !important;
}

.status-by-correction {
  color: $status-by-correction !important;
}

.status-processing {
  color: $status-processing !important;
}

.status-processed {
  color: $status-processed !important;
}

.status-consent {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.signed {
  background-color: $status-processed;
}

.sent {
  background-color: $status-duplicated;
}

.confirmed {
  background-color: $status-by-correction;
}

.draft {
  background-color: $status-draft !important;
}

.archived {
  background-color: $status-archived !important;
}

.cancelled {
  background-color: $status-cancelled !important;
}

.duplicated {
  background-color: $status-duplicated !important;
}

.to-process {
  background-color: $status-to-process !important;
}

.by-correction {
  background-color: $status-by-correction !important;
}

.processed {
  background-color: $status-processed !important;
}

.processing {
  background-color: $status-processing !important;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: #f06548 !important;
    //padding-right: calc(1.5em + 1rem);
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f06548'><circle cx='6' cy='6' r='4.5'/><path astroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23f06548' stroke='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right calc(1.6em + 0.25rem) center;
    background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.draft,
.cancelled,
.archived,
.duplicated,
.to-process,
.by-correction,
.processed {
  color: #ffffff !important;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 11px !important;

  &.btn {
    font-size: 13px !important;
  }
}

.p-component {
  font-size: 0.9rem !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon) {
  width: 1rem;
  height: 1rem;
}

.initials-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #212529;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  padding: 10px;

  &.large {
    width: 60px;
    height: 60px;
    font-size: 22px;
  }
}
